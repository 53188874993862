<template>
    <v-card>
        <v-card-title>
            <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            v-model="editedItem.label"
                            :label="trans('fields.common.label')"
                            :error-messages="errors.label"
                            @input="clearError('label')"
                        ></v-text-field>
                        <v-text-field
                            v-model="editedItem.code"
                            :label="trans('fields.common.code')"
                            :error-messages="errors.code"
                            @input="clearError('code')"
                        ></v-text-field>
                        <v-select
                            :items="options.languages"
                            :label="trans('fields.common.languages')"
                            :multiple="true"
                            v-model="editedItem.languagesArr"
                            :error-messages="errors.languagesArr"
                            @input="clearError('languagesArr')"
                        ></v-select>
                        <v-subheader class="ml-0 pl-0">{{ trans('pages.translations') }}</v-subheader>
                        <template v-if="localOptions.locales">
                            <v-text-field
                                v-for="locale in localOptions.locales"
                                :key="'translation-'+locale"
                                v-model="editedItem.translations[locale]"
                                :label="trans('fields.common.locale') + ' ' + locale"
                            ></v-text-field>
                        </template>

                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="$emit('close')">{{ trans('buttons.common.cancel') }}</v-btn>
            <v-btn v-if="editedItem.id" color="blue darken-1" text @click="save">{{ trans('buttons.common.save') }}</v-btn>
            <v-btn v-else color="blue darken-1" text @click="save">{{ trans('buttons.common.create') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import formValidate from "@/mixins/formValidate";
    export default {
        name: "CountryForm",
        mixins: [formValidate],
        components: {
        },
        props: {
            editedItem: {
                type: Object,
            },
            options: {
                type: Object,
            }
        },
        data(){
            return {
                localOptions: {},
            }
        },
        created() {
            this.loadOptions()
        },
        methods: {
            loadOptions(){
                axios.get(this.route('admin.options'), {params: {
                        locales: true,
                    }}).then( response => {
                    this.localOptions = response.data;
                })
            },
            save() {
                if (!this.editedItem.id) {
                    axios.post(this.route('admin.countries.store'), this.editedItem).then(() => {
                        console.log('save success');
                        this.$emit('saved');
                    }).catch( (err) => {
                        this.errors = err.response.data.errors;
                        console.log(err.response.data);
                    })
                } else {
                    axios.post(this.route('admin.countries.update', this.editedItem.id), this.editedItem).then(() => {
                        console.log('save success');
                        this.$emit('saved');
                    }).catch( (err) => {
                        this.errors = err.response.data.errors;
                        console.log(err.response.data);
                    })
                }
            },
        },
        computed: {
            formTitle() {
                return !this.editedItem.id
                    ? this.trans('pages.country.newDialogTitle')
                    : this.trans('pages.country.editDialogTitle');
            },
        }
    }
</script>
